import React from 'react'
import { graphql } from 'gatsby'
import WatPage from 'src/components/WatPage'

import 'antd/dist/antd.css' // or 'antd/dist/antd.less'
import 'static/shared-style/normalize.css'
import 'static/shared-style/custom.scss'

export default function Template({
  data // this prop will be injected by the GraphQL query below.
}) {
  return <WatPage wat={data.wat} product={data.product} />
}

export const pageQuery = graphql`
  query($path: String!, $products: [String!]) {
    wat: markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        name
        name2
        metaTitle
        metaDescription
        seoContent
        faq {
          question
          answer
        }
        useOwnProducts
        premiumSection {
          categoryName
          isShowSection
          products {
            best_seller
            selectedProduct
            id
            description
            colorTone
            descMobile
            image
            imageS3
            isShow
            position
            price
            size
          }
        }
        fSection {
          categoryName
          isShowSection
          products {
            best_seller
            selectedProduct
            id
            description
            colorTone
            descMobile
            image
            imageS3
            isShow
            position
            price
            size
          }
        }
      }
    }
    product: allMarkdownRemark(
      filter: { frontmatter: { layout: { eq: "product" }, id: { in: $products } } }
    ) {
      edges {
        node {
          id
          rawMarkdownBody
          frontmatter {
            best_seller
            colorTone
            id
            image
            imageS3
            isShow
            price
            position
            descMobile
            best_seller
            category
            size
          }
        }
      }
    }
  }
`
